<template>
  <main class="OrderlistManagement">
    <div style="text-align: left;">
      <Head title="订单管理"></Head>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 订单列表 -->
      <el-tab-pane label="订单列表" name="first">
        <!-- 输入框 -->
        <el-form
            :model="searchInput"
            size="small"
            inline
            status-icon
            label-width="140px"
            class="search-input"
        >
          <!-- 输入框 -->
          <!--          <el-row>-->
          <!--            <el-col :span="6">-->
          <!--              <el-input-->
          <!--                v-model="searchInput.productName"-->
          <!--                placeholder="请输入商品名称"-->
          <!--                clearable-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </el-col>-->
          <!--            <el-col :span="4" style="text-align: left">-->
          <!--              <el-button-->
          <!--                type="primary"-->
          <!--                size="small"-->
          <!--                style="font-size: 13px; line-height: 17px"-->
          <!--                @click="searchBtn"-->
          <!--                >搜索</el-button-->
          <!--              >-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </el-form>
        <el-table
            :data="tableData"
            style="width: 100%"
            border
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="selection" width="50" align="center" :selectable="checkboxT"
                           disabled='true'></el-table-column>
          <!-- <el-table-column type="index" width="50" label="序号" /> -->
          <el-table-column prop="orderId" align="center" label="订单号"></el-table-column>
          <el-table-column prop="productName" align="center" label="商品名称">
          </el-table-column>
          <el-table-column label="订单单价" width="100" align="center">
            <template slot-scope="scope">
              <span class="red">¥{{ scope.row.productPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="50" align="center">
            <template slot-scope="scope">
              <p style="text-aligin:center">{{ scope.row.quantity }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单价格" width="100" align="center">
            <template slot-scope="scope">
              <span class="red">¥{{ scope.row.orderPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createAt" align="center" label="下单时间" width="120">
            <template slot-scope="scope">
              <div>{{ splitDateAndTime(scope.row.createAt).date }}</div>
              <div>{{ splitDateAndTime(scope.row.createAt).time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" align="center" width="100">
            <template slot-scope="scope">
              <span style="color: #5daf34">{{ scope.row.statusName }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100" prop="" align="center" label="操作">
            <template slot-scope="scope">
              <!--              <div v-if="scope.row.status !== 1" class="btn-small">-->
              <!--                <el-button-->
              <!--                    type="text"-->
              <!--                    size="mini"-->
              <!--                    @click="handleOrderDetail(scope.row.orderId)"-->
              <!--                >查看详情-->
              <!--                </el-button>-->
              <!--              </div>-->
              <div class="btn-small" v-if="scope.row.status === 1">
                <el-button
                    type="text"
                    size="mini"
                    @click="handleCancel(scope.row.orderId)"
                >取消订单
                </el-button>
              </div>
              <div v-if="scope.row.refundFlag === 1" class="btn-small">
                <el-button
                    type="text"
                    size="mini"
                    @click="handleRefund(scope.row.orderId)"
                >申请退款
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pay_wrap">
          <div class="pay-left">支付金额：<span class="red">{{ payTotal }}元</span></div>
          <div class="pay-right">
            <el-button
                @click="handleOfflinePay"
                :disabled="isBuging"
                type="primary"
            >提交支付凭证
            </el-button
            >
          </div>
        </div>
        <div class="pagination">
          <Pagination
              :total="search.total"
              :page.sync="search.page"
              :limit.sync="search.size"
              @pagination="getData"
          />
        </div>
      </el-tab-pane>
      <!-- 支付记录 -->
      <el-tab-pane label="支付记录" name="third">
        <el-table
            :data="tableData3"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <!--          <el-table-column type="index" width="50" label="序号" />-->
          <el-table-column prop="orderId" align="center" label="订单号" width="150">
            <template slot-scope="scope">
              <div class="button-container">
                <el-button
                    type="text"
                    @click="handlePayDetail(scope.row)">
                  查看明细
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createAt" align="center" label="提交时间">
          </el-table-column>
          <!--          <el-table-column prop="refundId" label="退款申请单编号" width="150" />-->
          <el-table-column label="审核状态" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.auditStatus === 0" type="info">待审核</el-tag>
              <el-tag v-if="scope.row.auditStatus === 1" type="success">审核通过</el-tag>
              <el-tag v-if="scope.row.auditStatus === 2" type="danger">审核不通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <div class="btn-small">
                <el-button
                    type="primary"
                    size="mini"
                    @click="handInfo(scope.row)"
                >详情
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <Pagination
              :total="search3.total"
              :page.sync="search3.page"
              :limit.sync="search3.size"
              @pagination="getPayData"
          />
        </div>
      </el-tab-pane>
      <!-- 退款 -->
      <el-tab-pane label="退款管理" name="second">
        <!-- 输入框 -->
        <el-form
            :model="searchInput"
            size="small"
            inline
            status-icon
            label-width="140px"
            class="search-input"
        >
          <!-- 输入框 -->
          <!--          <el-row>-->
          <!--            <el-col :span="6">-->
          <!--              <el-input-->
          <!--                v-model="searchInput.productName"-->
          <!--                placeholder="请输入商品名称"-->
          <!--                clearable-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </el-col>-->
          <!--            <el-col :span="4" style="text-align: left">-->
          <!--              <el-button-->
          <!--                type="primary"-->
          <!--                size="small"-->
          <!--                style="font-size: 13px; line-height: 17px"-->
          <!--                @click="searchBtn2"-->
          <!--                >搜索</el-button-->
          <!--              >-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </el-form>
        <span style="font-size: 8px;color: red">退款金额在退款审核通过后15日内退回支付账户！</span>
        <el-table
            :data="tableData2"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="index" width="50" label="序号"/>
          <el-table-column prop="orderId" label="订单号" align="center" width="150"/>
          <el-table-column prop="productName" align="center" label="商品名称">
          </el-table-column>
          <!--          <el-table-column prop="refundId" label="退款申请单编号" width="150" />-->
          <el-table-column label="退款金额" align="center">
            <template slot-scope="scope">
              <span class="red">¥{{ scope.row.refundAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createAt" label="下单时间" align="center"></el-table-column>
          <el-table-column label="审核状态" align="center">
            <template slot-scope="scope">
              <el-tag>{{ scope.row.auditStatusName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="操作">
            <template slot-scope="scope">
              <!--              <div class="btn-small">-->
              <!--                <el-button-->
              <!--                  type="primary"-->
              <!--                  size="mini"-->
              <!--                  @click="handleOrderDetail(scope.row.orderId)"-->
              <!--                  >查看详情-->
              <!--                </el-button>-->
              <!--              </div>-->
              <div class="btn-small">
                <el-button
                    v-if="scope.row.auditStatus === 3"
                    type="text"
                    size="mini"
                    @click="handleAuditDetail(scope.row)"
                >审核意见
                </el-button>
              </div>
              <div v-if="scope.row.auditStatus === 1" class="btn-small">
                <el-button
                    type="text"
                    size="mini"
                    @click="handleCancelRefund(scope.row.refundId)"
                >取消退款申请
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <Pagination
              :total="search2.total"
              :page.sync="search2.page"
              :limit.sync="search2.size"
              @pagination="getRefundData"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 订单详情弹窗 -->
    <OrderDetailDialog
        v-if="orderDetailDialog.show"
        :order-detail-dialog="orderDetailDialog"
    />
    <!-- 支付凭证弹窗 -->
    <PayCertificateDialog
        v-if="payCertificateDialog.show"
        :pay-certificate-dialog="payCertificateDialog"
        @getlists="getlists"
    />
    <el-dialog title="审核意见" :visible.sync="visible" width="600px">
      <el-form>
        <el-form-item>
          <el-input type="textarea" rows="4" disabled v-model="auditRemark"></el-input>
        </el-form-item>
      </el-form>
      <div class="tip">审核失败，如需再次申请请返回订单列表</div>
    </el-dialog>

    <el-dialog :visible.sync="showDialog" title="订单明细">
      <table class="invoice-form__table">
        <thead>
        <tr>
          <th>订单号</th>
          <th>商品名称</th>
          <th>商品单价</th>
          <th>份数</th>
          <th>订单金额</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in targets" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.productPrice }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.orderPrice }}</td>
        </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showDialog = false">关闭</el-button>
  </span>
    </el-dialog>


    <el-dialog title="审核详情" :visible.sync="auditDialog" width="800px" append-to-body>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="审核状态">
          <el-tag v-if="form.auditStatus === 0" type="info">待审核</el-tag>
          <el-tag v-if="form.auditStatus === 1" type="success">审核通过</el-tag>
          <el-tag v-if="form.auditStatus === 2" type="danger">审核失败</el-tag>
        </el-form-item>
        <el-form-item label="下单时间">
          <span>{{ form.createAt }}</span>
        </el-form-item>
        <el-form-item label="支付明细">
          <table class="invoice-form__table">
            <thead>
            <tr>
              <th>订单号</th>
              <th>商品名称</th>
              <th>商品单价</th>
              <th>份数</th>
              <th>订单金额</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in targets" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.productPrice }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.orderPrice }}</td>
            </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-form-item label="支付凭证">
          <el-image
              style="width: 100px; height: 100px"
              :src="form.offlineImg"
              :previewSrcList="[form.offlineImg]">
          </el-image>
        </el-form-item>
        <!--        <el-form-item label="分配人数" prop="quantity">-->
        <!--          <el-input type="number" :min="0" :step="1" style="width: 100px" v-model="form.quantity"-->
        <!--                    placeholder="请输入分配人数"/>-->
        <!--        </el-form-item>-->
        <el-form-item label="申请备注">
          <el-input type="textarea" disabled style="width: 540px" :rows="4" v-model="form.remark"/>
        </el-form-item>
        <el-form-item v-if="undefined !== form.auditRemark" label="审核意见">
          <el-input type="textarea" style="width: 540px" :rows="4" v-model="form.auditRemark"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="auditDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </main>
</template>

<script>
import {
  getOrderList,
  getRefundList,
  cancelOrder,
  applyRefund,
  cancelApplyRefund,
  getOrderPayList,
} from "@/api/order";
import Pagination from "@/components/Pagination";
import OrderDetailDialog from "./components/OrderDetailDialog";
import PayCertificateDialog from "./components/PayCertificateDialog";
import Head from './Head'

export default {
  name: "OrderlistManagement",
  components: {
    Pagination,
    OrderDetailDialog,
    PayCertificateDialog,
    Head
  },
  data() {
    return {
      form: {},
      auditDialog: false,
      showDialog: false,
      targets: [],
      payTotal: 0, //
      isBuging: true,
      visible: false,
      searchInput: {}, //搜索表单
      activeName: "first", //选中状态
      productName: [],
      state: "",
      auditRemark: "",
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      search2: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      search3: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      orderDetailDialog: {},
      payCertificateDialog: {},
      tableData: [],
      tableData2: [],
      tableData3: [],
    };
  },
  watch: {
    payRadio: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        /* if (newVal !== null) {
          this.isBuging = false;
        } else {
          this.isBuging = true;
        } */
      },
      immediate: true
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    handInfo(row) {
      this.form = row;
      this.targets = JSON.parse(row.targetObjs);
      this.auditDialog = true;
    },
    splitDateAndTime(dateTime) {
      const [date, time] = dateTime.split(' ');
      return {date, time};
    },
    handlePayDetail(row) {
      this.targets = JSON.parse(row.targetObjs);
      this.showDialog = true;
    },
    //获取订单数据
    getData() {
      getOrderList(this.search).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            const {data} = res.data;
            this.search.total = data.total; // 共几条数据
            this.tableData = data.rows; // 绑定数据
            //  this.productName = data.rows.productName;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    getPayData() {
      getOrderPayList(this.search3).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            const {data} = res.data;
            this.search3.total = data.total; // 共几条数据
            this.tableData3 = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    // 获取退款订单
    getRefundData() {
      getRefundList(this.search2).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            const {data} = res.data;
            this.search2.total = data.total; // 共几条数据
            this.tableData2 = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    handleAuditDetail(row) {
      this.auditRemark = row.remark
      this.visible = true;
    },
    handleCancel(orderId) {
      cancelOrder({orderId: orderId}).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            // const { data} = res.data;
            // this.txToken = data.txToken;
            this.$message({
              type: "success",
              message: "取消订单成功",
            });
            this.getData()
          } else {
            this.$message({
              type: "error",
              message: "取消订单失败",
            });
          }
        }
      });
    },
    handleOrderDetail(orderId) {
      this.orderDetailDialog.show = true;
      this.orderDetailDialog = {
        show: true,
        title: "订单详情",
        orderId: orderId,
      };
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === "second") {
        /* this.search = Object.assign(this.search,{
          refund: true
        }) */
        this.getRefundData();
      } else if (tab.name === "third") {
        this.getPayData();
      } else {
        this.search = Object.assign(this.search, {
          // refund: false
        });
        this.getData();
      }
    },
    searchBtn() {
      if (!this.searchInput.productName) {
        this.$message({
          type: "error",
          message: "商品名称不能为空！",
        });
      } else {
        this.search = Object.assign(this.search, {
          productName: this.searchInput.productName,
          // refund: false
        });
        this.getData();
      }
    },
    searchBtn2() {
      if (!this.searchInput.productName) {
        this.$message({
          type: "error",
          message: "商品名称不能为空！",
        });
      } else {
        this.search = Object.assign(this.search, {
          productName: this.searchInput.productName,
          // refund: true
        });
        this.getData();
      }
    },
    //申请退款
    handleRefund(orderId) {
      applyRefund({orderId: orderId}).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: res.data.message,
          });
          this.getData();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //取消退款申请
    handleCancelRefund(refundId) {
      cancelApplyRefund({refundId: refundId}).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: res.data.message,
          });
          this.getRefundData();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
      let tmpOrderId = []
      this.payTotal = 0;
      this.multipleSelection.forEach(item => {
        tmpOrderId.push(item.orderId)
        this.payTotal = parseFloat((this.payTotal + item.payPrice).toFixed(2));
      })
      if (tmpOrderId.length > 0) {
        this.isBuging = false
      } else {
        this.isBuging = true
        this.payTotal = 0
      }
    },
    checkboxT(row) {
      if (row.status === 1) {
        return 1
      } else {
        return 0
      }
    },
    // 提交支付凭证
    handleOfflinePay() {
      this.payCertificateDialog.show = true;
      this.payCertificateDialog = {
        show: true,
        title: "提交支付凭证",
        totalPrice: this.payTotal,
        orderLists: this.multipleSelection
      };
    },
    // 弹窗回调
    getlists() {
      this.getData()
    }
  },
};
</script>

<style lang="scss" scoped>
.OrderlistManagement {
  //span {
  //  margin-left: 15px;
  //}

  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }

  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }

  //.btn-small {
  //  margin-bottom: 10px;
  //}


  .pay_wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0 0 0;
    .pay-left {
      margin-right: 60px;
    }
  }

  .pagination {
    margin-top: 30px;
  }

  .red {
    color: red;
    font-weight: bold;
  }

  .el-table {
    margin-top: 10px;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-form__table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.invoice-form__table th,
.invoice-form__table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.invoice-form__table th {
  background-color: #f2f2f2;
}

.invoice-form__table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}
</style>