<template>
  <el-dialog
    v-if="orderDetailDialog.show"
    :title="orderDetailDialog.title"
    :visible.sync="orderDetailDialog.show"
    center
    width="40%"
    top="20vh"
  >
    <div class="cont">
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">订单Id：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">{{ info.orderId }}</div></el-col
        >
      </el-row> -->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">产品名：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">{{ info.productName }}</div></el-col
        >
      </el-row> -->
<!--      <el-row>-->
<!--        <el-col :span="8"><div class="l-title">商品原价：</div></el-col>-->
<!--        <el-col :span="16"-->
<!--          ><div class="r-cont red">¥{{ info.productPrice }}</div></el-col-->
<!--        >-->
<!--      </el-row>-->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">订单状态：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">{{ info.statusName }}</div></el-col
        >
      </el-row> -->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">订单创建时间：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">{{ info.createAt }}</div></el-col
        >
      </el-row> -->
      <el-row>
        <el-col :span="8"><div class="l-title">支付名称：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont">{{ info.payName }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">支付金额：</div></el-col>
        <el-col :span="16"
          ><div class="r-cont red">¥{{ info.payPrice }}</div></el-col
        >
      </el-row>

      <template v-if="info.payMethod === 2">
        <el-row>
          <el-col :span="8"><div class="l-title">付款账户名称：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont">{{ info.offlineAccountName }}</div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"><div class="l-title">转账时间：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont">{{ info.offlinePayTime }}</div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"><div class="l-title">转账截图：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont"><a :href="info.offlineImg" target="_blank">{{info.offlineImg}}</a></div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"><div class="l-title">审核状态：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont">{{ info.auditStatusName }}</div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"><div class="l-title">备注：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont">{{ info.remark }}</div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"><div class="l-title">审核意见：</div></el-col>
          <el-col :span="16"
            ><div class="r-cont">{{ info.auditRemark }}</div></el-col
          >
        </el-row>
      </template>
      <div class="btn-wrap">
        <el-button @click="goBack" type="primary">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getOrderDetail } from '@/api/order'
export default {
  name: 'OrderDetailDialog',
  props: {
    orderDetailDialog: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      info: {} // 订单信息
    }
  },
  mounted() {
    console.log('ccc...')
    this.getData(this.orderDetailDialog.orderId)
  },
  methods: {
    getData(orderId) {
      getOrderDetail(orderId).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const { data } = res.data
            this.info = data
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
          console.log(this.info)
        }
      })
    },
    goBack() {
      this.orderDetailDialog.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  margin: 0px auto 0;
  .l-title {
    margin: 10px 0;
    text-align: right;
    color: #666;
  }
  .r-cont {
    color: #333;
    margin: 10px 0;
    text-align: left;
  }
  .red {
    color: red;
    font-weight: bold;
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: center;
  }
}
</style>