<template>
  <el-dialog
    v-if="payCertificateDialog.show"
    :title="payCertificateDialog.title"
    :visible.sync="payCertificateDialog.show"
    center
    width="60%"
    top="15vh"
  >
    <div class="main">
      <div class="cont">
        <div class="item">
          <div class="title"><span class="tag"></span>订单列表：</div>
          <el-table
            :data="payCertificateDialog.orderLists"
            class="tableData"
            border
            :header-cell-style="{ background: '#f8f8f8' }"
          >
            <el-table-column type="index" label="序号" width="50" />
            <el-table-column prop="orderId" label="订单ID" />
            <!-- <el-table-column prop="productId" label="产品ID" /> -->
            <el-table-column prop="productName" label="商品名称" />
            <el-table-column label="产品数量" align="center">
              <template slot-scope="scope">
                x {{scope.row.quantity}}
              </template>
            </el-table-column>
            <el-table-column label="订单价格/(元)" align="center">
              <template slot-scope="scope">
                ¥ {{ scope.row.orderPrice }}
              </template>
            </el-table-column>
            <el-table-column label="支付价格/(元)" align="center">
              <template slot-scope="scope">
                <span class="red">¥ {{ scope.row.payPrice }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="item">
          <div class="title">
            <span class="tag"></span> 订单总金额(元)
          </div>
          <span style="color: red">{{payCertificateDialog.totalPrice}}</span>

        </div>
        <div class="item">
          <div class="title">
            <span class="tag"></span>收款信息：(请按如下账户打款)
          </div>
          <el-table
              :data="tableData2"
              class="tableData"
              border
              :header-cell-style="{ background: '#f8f8f8' }"
          >
            <el-table-column prop="accountName" label="账户名" />
            <el-table-column prop="cardNo" label="帐号" />
            <el-table-column prop="bankName" label="开户行" />
          </el-table>
        </div>
        <div class="item">
          <div class="title"><span class="tag"></span>付款信息：</div>
          <div class="pay-cont" style="padding-bottom:20px">
            <el-form
              ref="payForm"
              :model="payForm"
              :rules="payFormRules"
              class="payForm"
              label-width="130px"
            >
              <el-form-item prop="name" label="付款账户名称：">
                <el-input
                  v-model="payForm.name"
                  clearable
                  placeholder="请输入付款账户名称"
                  class="phone"
                  tabindex="1"
                />
              </el-form-item>
              <el-form-item prop="time" label="转账时间：">
                <el-date-picker
                  v-model="payForm.time"
                  type="datetime"
                  placeholder="请选择转账时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                  tabindex="2"
                  style="width:300px"
                />
              </el-form-item>
              <el-form-item prop="imgUrl" label="转账截图：">
                <div>
                  <Image-upload
                    :file-arr="payForm.img_url"
                    @getUploadList="getLogoList"
                  />
                  <p class="img-tip">支持JPG\JPEG\PNG格式，不超过5M！</p>
                </div>
              </el-form-item>
<!--              <el-form-item label="产品原价：">-->
<!--                <el-input-->
<!--                  v-model="totalProPrice"-->
<!--                  clearable-->
<!--                  disabled-->
<!--                  class="phone"-->
<!--                  tabindex="3"-->
<!--                /><span class="inpunt-text"> 元</span>-->
<!--              </el-form-item>-->
              <el-form-item label="支付金额：">
                <el-input
                  v-model="totalPayPrice"
                  clearable
                  disabled
                  class="phone"
                  tabindex="4"
                /><span class="inpunt-text"> 元</span>
              </el-form-item>
              <!-- <el-form-item prop="amount" label="订单金额：">
                <el-input
                  v-model="payForm.amount"
                  type="number"
                  clearable
                  placeholder="请输入订单金额"
                  class="phone"
                  tabindex="2"
                /><span class="inpunt-text"> 元</span>
              </el-form-item> -->
              <el-form-item label="备注：">
                <el-input
                  v-model="payForm.remark"
                  clearable
                  placeholder="请输入备注"
                  class="phone"
                  type="textarea"
                  :rows="3"
                  tabindex="3"
                />
              </el-form-item>
              <!-- <div class="btn-wrap">
            <el-button
              @click="handleOfflinePay"
              :disabled="isBuging"
              type="primary"
              >提交订单</el-button
            >
          </div> -->
            </el-form>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <!-- <img src="https://www.wlmqcol.com/assets/img/order/wechat.png?version=8.8.0"   @click="handlePayMethod(index)" :class="{img}"> -->
        <el-button @click="handleOrder" :disabled="isBuging" type="primary"
          >提交审核</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ImageUpload from "@/components/Upload/ImageUpload"; // 4-16
import { /*submitOrder,*/ getPayMethod,payRecord /*getTxToken*/ } from "@/api/order";
export default {
  components: {
    ImageUpload,
  },
  props: {
    payCertificateDialog: {
      type: Object,
      default: null,
    },
    orderLists: {
      type: Array,
    },
  },
  data() {
    return {
      isBuging: false,
      totalProPrice: 0, //支付原价
      totalPayPrice: 0, //所有订单加载一起需要支付的实际金额

      payImage: null, //提交转账截图
      pickerOptions: {},
      payForm: {
        // imgUrl: uploadDefault,
        img_url: [],
      },
      tableData2: [],
      payFormRules: {
        name: [
          { required: true, message: "付款账户名称不能为空", trigger: "blur" },
        ],
        time: [
          {
            required: true,
            message: "付款账户名称不能为空",
            trigger: "change",
          },
        ],
        amount: [
          { required: true, message: "订单金额不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getPayMethod();
    console.log(this.payCertificateDialog.orderLists);

    //所有订单加载一起需要支付的实际金额 与 需要支付的原价
    if(this.payCertificateDialog.orderLists.length > 0) {
      this.payCertificateDialog.orderLists.forEach(item=>{
        this.totalProPrice = parseFloat((this.totalProPrice + item.orderPrice).toFixed(2));
        this.totalPayPrice = parseFloat((this.totalPayPrice + item.payPrice).toFixed(2));
      })
    }
  },
  methods: {
    // 获取支付方式
    getPayMethod() {
      getPayMethod().then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.payData = res.data.data.payMethods;
            this.payRadio = this.payData[0].payMethod;
            this.tableData2.push({
              accountName: res.data.data.offlineBank.accountName,
              bankName: res.data.data.offlineBank.bankName,
              cardNo: res.data.data.offlineBank.cardNo,
            });
          }
        }
      });
    },
    // 获取收集点门面照片
    getLogoList(val) {
      this.payForm.img_url = val;
      this.payImage = this.payForm.img_url;
    },
    handleOrder() {
      console.log("线下支付提交....");
      console.log(this.payImage);
      if(this.payImage === null || this.payImage === undefined) {
        this.$message({
          type: 'error',
          message: '请上传转账凭证截图'
        })
        return
      }else{
        // await this.getTxToken()
        this.$refs.payForm.validate((vaild) => {
          if(vaild){
            this.handleSubmit()
          }else{
            console.log('nopass');
          }
        })
      }
    },
    handleSubmit() {
      //所有订单数据
      let tmpArray = []
      if(this.payCertificateDialog.orderLists.length > 0) {
        this.payCertificateDialog.orderLists.forEach(item=>{
          let tmpObj= {
            id: item.orderId,
            productId: item.productId,
            name: item.productName,
            quantity: item.quantity,
            orderPrice: item.orderPrice,
            payPrice: item.payPrice,
            productPrice: item.productPrice
          }
          tmpArray.push(tmpObj)
        })
      }

      const obj = {
        targetsObj: tmpArray,
        totalOrderPrice: this.totalProPrice,
        totalPayPrice: this.totalPayPrice,
        offlineAccountName: this.payForm.name,
        offlinePayTime: this.payForm.time,
        offlineImg: this.payImage[0],
        remark: this.payForm.remark
      }
      payRecord(obj).then((res) => {
        // console.log(res);
        if (res.data.code === 0) {
          this.$message({
            type:'success',
            message: res.data.message
          })
          this.payCertificateDialog.show = false
          this.$emit('getlists')
        }else{
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
      
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  .cont {
    position: relative;
    height: 600px;
    overflow-y: auto;
    .item {
      margin-top: 20px;
      .title {
        font-size: 14px;
        color: #333;
        font-weight: bold;
        text-align: left;
        margin: 40px 0 20px 0;
        border-bottom: 1px #ebebeb solid;
        padding-bottom: 8px;
        .tag {
          border-left: 3px solid #409eff;
          margin-right: 10px;
        }
      }
      .img {
        width: 200px;
        height: 150px;
      }
      .PayMethod {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
      }
      .pay-cont {
        text-align: left;
        margin-top: 20px;
        .pay-radio {
          // display: flex;
        }
        img {
          border: none;
          padding: 3px;
          border: 2px #f5f5f5 solid;
          width: 150px;
          height: 50px;
          margin-top: 0;
        }
        .border {
          border: 1px #43bc60 solid;
        }
        /deep/ .el-radio__input {
          display: none;
        }
        .payForm {
          width: 600px;
          .phone {
            width: 300px;
          }
        }
        .buy_wrap {
          display: flex;
          width: 500px;
          // border:1px #f00 solid;
          .buy_tit {
            line-height: 40px;
            font-size: 14px;
          }
          .buy_cont {
            .buy_input {
              width: 80px;
              margin: 0 10px;
            }
          }
        }
      }
      img {
        float: left;
        margin-top: 5px;
        border: 1px solid #eee;
        padding: 5px;
      }
      .el-button--medium {
        // margin-top: 50px;
      }
      /* .el-table tbody tr:hover>td { 
      background-color:#ffffff!important
    }
    .el-table__footer-wrapper tbody tr:hover > td { 
      background-color:#ebebeb!important;
    }
    .el-table__footer-wrapper tbody tr td { 
      color:#f00
    } */
    }
  }
  .btn-wrap {
    position: absolute;
    width: 100%;
    height: 50px;
    background: #fff;
    padding-top:10px;
    border-top:1px #eee solid;
    // border: 1px #f00 solid;
    bottom: 0px;
    right: 0px;
    margin-top: 20px;
    text-align: center;
  }
  .red{
    color:red;
  }
}
</style>